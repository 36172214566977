
TTI.Incident = function(spec) {

	var lazyIconURL = false;

	var self = TTI.Asset(spec);
	self.marker = null;
	self.spec = spec;

	/**
	self.allTagsString = '';
	var tags = [];
	var fields = ['ACCAUSE','CARNBR1','CARNBR2','CAUSE','COUNTY','INCDTNO','INCDTNO2','INCDTNO3','NARR1','NARR2','RAILROAD','RR3','STATION','SUBDIV','YEAR4'];
	fields.forEach(function(property) { 
		if (spec[property]) {
		  tags = tags.concat(TTI.wordify(spec[property]));    
		}
	});
	tags = tags.unique();
	///console.log('tags',tags);
	self.allTagsString = '/' + TTI.brickify(tags,'/') + '/';
	//console.log('allTagsString',self.allTagsString);
	***/

	self.getLL = function() {
		if (!spec.LATITUDE || !spec.LONGITUDE) { return false; }
		if (spec.LATITUDE == "0" || spec.LONGITUDE == "0") { return false; }
		if (spec.LONGITUDE == "-123.456") { return false; }

		return TTI.Point({
			lat: spec.LATITUDE,
			lng: spec.LONGITUDE
		});
	};

	self.getDateString = function() {
		var result = '';
		if (!spec.YEAR_ || !spec.MONTH_ || !spec.DAY_) {
			return 'No Date';
		}
		if (isNaN(spec.YEAR_) || isNaN(spec.MONTH_) || isNaN(spec.DAY_)) {
			return 'No Date';
		}
		result += spec.YEAR_ + '-';
		result += ('0' + spec.MONTH_).substr(-2) + '-';
		result += ('0' + spec.DAY_).substr(-2);
		return result;
	};


	self.linkedPDFIcon = function() {
		var result = DOM.a(
			DOM.img()
				.attr('src',TTI.baseURL + '/images/PDF_32.png')
     )
			.attr('href',self.form54ReportURL())
      .attr('target','_blank');
    return result;
	};

	self.renderPopupContentOn = function(wrap) {
		wrap.append(
			DOM.div(
				DOM.strong(self.getDateString())
				//DOM.strong(spec.UNIQUEID),
				//DOM.strong(spec.ACCAUSE)
			),
			DOM.div(spec.RR3),
			DOM.div(spec.Type_ACC),
			DOM.div(spec.CAUSE_ACC),
			self.linkedPDFIcon(),
			DOM.i()
				.addClass('fa fa-2x fa-info-circle info-circle')
				.on('click',function(){
					campfire.publish('inspect-incident',self);
				})
		);
	};

	self.renderL = function(entity,opts) {

		var where = self.getLL();
		if (!where) {
			///return false;//console.log('cannot render incident here',where,self);
			return self;
		}

		var wrap = DOM.div();
		
		// Size of dot on the map tied to the crash damage cost.
		var damageValue = spec.TOTALDAMAG || 0;
		var minDamage = 10000;
		var maxDamage = 1000000;
		var minScale = 1;
		var maxScale = 1.5;
		var markerScale = minScale + (maxScale - minScale) * (damageValue - minDamage) / (maxDamage - minDamage);
		markerScale = Math.round(markerScale * 100) / 100;

		self.renderPopupContentOn(wrap);
		///circle.bindPopup(wrap.html());
		var icon = L.icon({
			// scaleModifier: markerScale, // Used for marker cluster scale.
			iconUrl:   TTI.cache.icons[spec.RR3],
			////iconUrl: TTI.baseURL + '/images/RR/default.png',
			//iconUrl: 'images/dms.png',
			///shadowUrl: 'leaf-shadow.png',
			// iconSize: [30, 30].map(function (n) { return Math.round(n * markerScale); }),
			iconSize: [30, 30],
			//iconSize:     [38, 95], // size of the icon
			//shadowSize:   [50, 64], // size of the shadow
			//iconAnchor:   [22, 94], // point of the icon which will correspond to marker's location
			//shadowAnchor: [4, 62],  // the same for the shadow
			//popupAnchor:  [-3, -76] // point from which the popup should open relative to the iconAnchor
		});




		var marker = L.marker(self.getLL(), {icon: icon});
		marker.addTo(entity);
		marker.bindPopup(wrap[0]);
		marker.on('mousedown',function() {
			//waiter.beg(marker.openPopup);
			if (opts.scrollTo) {
			opts.scrollTo.scrollIntoView({
				behavior: 'smooth',
				block: 'center',
				inline: 'start'
			});
			$('tr.poi').removeClass('poi');
			jQuery(opts.scrollTo).addClass('poi');
			}

			window.map.closePopup();
			window.waiter.beg(function() {
			return marker.openPopup();
			});
		});
		marker.on('click',function(){
			campfire.publish('show-asset-details',self);
		});
		marker.bindPopup(wrap[0]); //bindPopup requires the HTMLElement, not the 		
		window.map.on('moveend', function() {
			window.map.closePopup();
		});
		self.marker = marker;
		return marker;
	};
	
	self.getMarker = function() {
		return self.marker;
	};

	self.form54ReportURL = function() {
	  var value = spec.Weblink
		? spec.Weblink
		: `https://safetydata.fra.dot.gov/Officeofsafety/Publicsite/FORM54/F54Report.aspx?txtf54key=${spec.LinkID}`;
	  if (value.endsWith('txtf54key=0')) {
	    value = '';
	  }
	  return value;
	};
	self.causeAlpha = function() {
		return spec.CAUSE.substr(0,1);
	};

	self.isHazmatRelated = function() {
		var sum = spec.CARHAZDAM + spec.CARSHAZNO + spec.CARSHZREL;
		return sum > 0;
	};
	self.disciplineCodes = function() {
		var result = [];
		if (self.isHazmatRelated()) {
			result.push("HAZMAT");
		}
		var causeAlpha = self.causeAlpha();
		if (causeAlpha.match(/T|S|H|E/)) { 
			result.push(causeAlpha);
		}
		return result;
	};

	self.railroadAliases = function() {
		var result = [spec.RR3,TTI.cache.railroads[spec.RR3]]
			.map(o => o);
		return result;
	};



	self.countyName = function()  {
		var hit = TTI.counties.detect(function(county){
			return spec.CTFIPS == county.combinedFIPS();
		});
		if (hit) {
			return hit.spec.COUNTYNAME;
		}
	};
	return self;
}

TTI.returnTrue = function (o) {
  return true;
};
TTI.identity = function (o) {
  return o;
};
TTI.tagScore = function (a, b, commonScore) {
  var common = a.intersect(b);
  common = common.unique();
  var extraTagCount = a.length + b.length - 2 * common.length; //extra tags in the hit.
  var score = commonScore * common.length - extraTagCount; //penalize the rank of extra tags somewhat, Not to the order of mangitude that we rank common tags though.
  return score;
};

TTI.scrollTop = function () {
  var doc = document.documentElement,
    body = document.body;
  var left = (doc && doc.scrollLeft) || (body && body.scrollLeft) || 0;
  var top = (doc && doc.scrollTop) || (body && body.scrollTop) || 0;
  return top;
};

TTI.sorter = function (picker) {
  var func = function (a, b) {
    if (picker(a) < picker(b)) {
      return -1;
    }
    if (picker(a) > picker(b)) {
      return 1;
    }
    return 0;
  };
  return func;
};

TTI.importJSON = function (url, callback) {
  jQuery.ajax({
    type: "GET",
    url: url,
    dataType: "json",
    success: function (data) {
      callback(null, data);
    },
    error: callback,
  });
};

TTI.import = function (url, callback) {
  jQuery.ajax({
    type: "GET",
    url: url,
    success: function (data) {
      callback(null, data);
    },
    error: callback,
  });
};

TTI.importXLSX = function (url, callback) {
  /* set up async GET request */
  var req = new XMLHttpRequest();
  req.open("GET", url, true);
  req.responseType = "arraybuffer";

  req.onload = function (e) {
    var data = new Uint8Array(req.response);
    ///console.log('uint8array created');
    var workbook = XLSX.read(data, { type: "array" });
    ///console.log('workbook created from XLSX.read');
    return callback(null, workbook);
    /* DO SOMETHING WITH workbook HERE */
  };
  req.onerror = function (e) {
    return callback(e);
  };
  req.onprogress = function (e) {
    ///console.log('progress?',e);
  };
  req.send();
};

TTI.wasimportXLSX = function (url, callback) {
  /* set up XMLHttpRequest */
  ////////var url = TTI.themeURL + '/data/ashesh-motorcycle-data.xlsx';
  var oReq = new XMLHttpRequest();
  oReq.open("GET", url, true);
  oReq.responseType = "arraybuffer";

  oReq.onload = function (e) {
    var arraybuffer = oReq.response;

    /* convert data to binary string */
    var data = new Uint8Array(arraybuffer);
    var arr = new Array();
    for (var i = 0; i != data.length; ++i)
      arr[i] = String.fromCharCode(data[i]);
    var bstr = arr.join("");

    /* Call XLSX */
    var workbook = XLSX.read(bstr, { type: "binary" });

    /* DO SOMETHING WITH workbook HERE */
    callback(null, workbook);
    ///TTI.workbook = workbook;
    ////console.log(workbook);
  };
  oReq.onerror = function (e) {
    return callback(e);
  };
  oReq.send();
};

function isAllCommas(line) {
  return line.match(/^,+$/);
}

TTI.workbookToJSON = function (workbook) {
  var result = {};
  workbook.SheetNames.forEach(function (sheetName) {
    var roa = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
    if (roa.length > 0) {
      result[sheetName] = roa;
    }
  });
  return result;
};

TTI.wordify = function (phraseString) {
  if (typeof phraseString == "number") {
    return [phraseString.toString()];
  }
  if (Array.isArray(phraseString)) {
    phraseString = phraseString.join(" ");
  }
  if (typeof phraseString == "object") {
    return []; //skip this for now... abstract.href on publications...
  }
  if (!phraseString) {
    return [];
  }
  if (typeof phraseString == "number") {
    phraseString = phraseString.toString();
  }
  let result = phraseString.split(/\ +/).map(function (o) {
    return o.trim().toUpperCase();
  });
  return result;
};

function geoBounds(points) {
  var ascLat = points
    .map((o) => o.lat)
    .filter((o) => o)
    .sort();
  var ascLng = points
    .map((o) => o.lng)
    .filter((o) => o)
    .sort();

  var result = [
    [ascLat[ascLat.length - 1], ascLng[0]], //upper left lat/long
    [ascLat[0], ascLng[ascLng.length - 1]], //lower right lat/long
  ];
  return result;
}

TTI.brickify = function (words, delimiter) {
  if (typeof words == "string") {
    words = TTI.wordify(words);
  }

  var result = words
    .map(function (word) {
      return word.replace(/\ +/, "").trim().toUpperCase();
    })
    .join(delimiter);

  result = result.replace(/\xb7/, "");

  /// return o.str.replace(/\ +/,'').trim(); }).join("");
  return result;
};

TTI.importExcelSheet = function (url, worksheetName, callback) {
  TTI.importXLSX(url, function (err, wb) {
    ///console.log('import done');
    if (err) {
      return callback(err);
    }
    var doc = TTI.workbookToJSON(wb);
    ///console.log('got JSON now');
    var sheet = doc[worksheetName];
    if (!sheet) {
      return callback("did not find a sheet called " + worksheetName);
    }
    var results = sheet.filter(function (o) {
      return o && Object.keys(o).length > 1;
    });
    ///console.log('filtered out bad rows');
    callback(null, results);
  });
};

TTI.segmentify = function (ary) {
  ///[A,B,C,D,E,F] => [[A,B],[B,C],[C,D],[D,E],[E,F]]
  var result = [];
  for (var i = 0; i < ary.length - 1; i += 1) {
    var tup = [ary[i], ary[i + 1]];
    result.push(tup);
  }
  return result;
};

TTI.histogram = function (a) {
  var hash = {};
  a.forEach(function (o) {
    hash[o] ? hash[o]++ : (hash[o] = 1);
  });
  return hash;
};

TTI.cleanQuery = function (str) {
  return str;
};

function tagThem(results) {
  results.forEach(function (o) {
    var kw = [];
    Object.keys(o.spec).forEach(function (k) {
      kw = kw.concat(TTI.wordify(o.spec[k]));
    });
    kw = kw.filter((o) => o.length > 0);
    o.allTagsString = TTI.brickify(kw, "/");
  });
  return results;
}

function tagThemWithProps(results, props) {
  results.forEach(function (o) {
    var kw = [];
    props.forEach(function (k) {
      kw = kw.concat(TTI.wordify(o.spec[k]));
    });
    kw = kw.filter((o) => o.length > 0);
    o.allTagsString = TTI.brickify(kw, "/");
  });
  return results;
}

function wordBrick(origStr) {
  var result = "/" + TTI.brickify(TTI.wordify(origStr), "/") + "/";
  return result;
}

function inspect(asset) {
  campfire.publish("show-lightbox", function (wrap) {
    wrap.append(DOM.pre(JSON.stringify(asset, null, 4)));
  });
}

TTI.findIt = function (query, candidates, propertyFunc) {
  query = TTI.cleanQuery(query);

  var queryTags = TTI.wordify(query);
  queryTags = queryTags.select(TTI.identity);

  var scoredCandidates = candidates
    .select(TTI.returnTrue)
    .map(function (candidate) {
      var ptnQuery = propertyFunc(candidate).trim(); //propertyfunc needs to return array of tags.
      ptnQuery = TTI.cleanQuery(ptnQuery);
      var ptnTags = TTI.wordify(ptnQuery);
      ptnTags = ptnTags.select(TTI.identity); //must be array

      var score = TTI.tagScore(queryTags, ptnTags, 20);
      return { score: score, candidate: candidate };
    });

  var positives = scoredCandidates.select(function (o) {
    return o.score > 0;
  });
  var sorted = positives.sort(
    TTI.sorter(function (o) {
      return o.score;
    })
  );
  ////if (sorted.length == 0) { return []; }

  //if only one result was desired, continue on beyond this next return
  return sorted; //contains both the score and the candidate for each result.

  /**
  let result = sorted.map(function(s){ 
    return s.candidate; 
  });
  return result;
  **/
  /**
  let result = sorted[sorted.length-1];
  return result.candidate;
  **/
};

TTI.serverSideIncidentSearch = function (keywords, fields, success, error) {
  keywords = keywords.select(function (o) {
    return o.length > 0;
  });
  jQuery.ajax({
    type: "GET",
    url: TTI.baseURL + "/ws",
    data: { action: "incident_search", keywords: keywords, fields: fields },
    dataType: "json",
    async: true,
    success: function (specs) {
      var conjured = specs.map(function (o) {
        return TTI.Incident(o);
      });
      success(conjured);
    },
  });
  return false;
};

var campfire = TTI.PubSub({});

///TTI.filterDataTypes

function getColumnLabel(fieldName) {
  let columns = [
    { label: "Year of incident", value: "iyr3" },
    { label: "Month of incident", value: "imo3" },
    { label: "Railroad assigned number", value: "incdtno3" },
    {
      label: "Railroad code (RR responsible for track maintenance",
      value: "rr3",
    },
    { label: "Type of accident", value: "type_acc" },
    { label: "Aggregated # of cars carrying hazmat", value: "carshazno" },
    {
      label: "Aggregated # of hazmat cars damaged or derailed",
      value: "carhazdam",
    },
    { label: "Aggregated # of cars that released hazmat", value: "carshzrel" },
    { label: "Nearest city and town", value: "station" },
    { label: "Type of consist", value: "type_consi" },
    { label: "FRA track class", value: "trkclass" },
    {
      label: "Annual track density-gross tonnage in millions",
      value: "ann_den",
    },
    { label: "Type of Track", value: "typetrk" },
    { label: "Primary cause of incident", value: "cause" },
    { label: "Details of accident cause", value: "cause_acc" },
    { label: "Contributing cause of incident", value: "cause_2" },
    { label: "Contributing Details", value: "cause_acc2" },
    { label: "Total killed for all RRs involved", value: "totalfatal" },
    { label: "Total injured for all RRs involved", value: "totalinj" },
    { label: "Milepost #", value: "milepost" },
    { label: "Latitude", value: "latitude" },
    { label: "Longitude", value: "longitude" },
    {
      label: "Federal Information Processing Standard (FIPS) State Code",
      value: "ctfips",
    },
    { label: "Quality of geolocation", value: "quality" },
    { label: "how accident was geolocated", value: "processtyp" },
    {
      label: "Total reportable damage on all reports in $",
      value: "totaldamag",
    },
    { label: "Coding of Type of Accident", value: "type" },
    { label: "Categorization for Cause Code", value: "sub_cause" },
    { label: "Higher Categorization for Cause Code", value: "high_subca" },
    { label: "LinkID", value: "linkid" },
    { label: "State Abbreviation", value: "state_abbr" },
    { label: "Year", value: "year_" },
    { label: "Month", value: "month_" },
    { label: "Day", value: "day_" },
    { label: "Hyperlink to the Accident report", value: "weblink" },
    {
      label: "Federal Information Processing Standard (FIPS) State Code",
      value: "fips",
    },
    { label: "Year", value: "year1" },
    { label: "Month", value: "month1" },
    { label: "Day", value: "day1" },
    //{ label: '', value: 'remove' },
    //{ label: '', value: 'mmyear' },
  ];

  var hit = columns.detect(function (column) {
    return column.value.toLowerCase() == fieldName.toLowerCase();
  });

  if (!hit) {
    return "no label hit for column " + fieldName;
  }
  return hit.label;
}

//counties
/**

"[
    "Anderson County",
    "Angelina County",
    "Armstrong County",
    "Atascosa County",
    "Austin County",
    "Bailey County",
    "Bastrop County",
    "Bell County",
    "Bexar County",
    "Bosque County",
    "Bowie County",
    "Brazoria County",
    "Brazos County",
    "Brewster County",
    "Brown County",
    "Burleson County",
    "Burnet County",
    "Caldwell County",
    "Calhoun County",
    "Callahan County",
    "Cameron County",
    "Camp County",
    "Carson County",
    "Cass County",
    "Castro County",
    "Chambers County",
    "Cherokee County",
    "Childress County",
    "Clay County",
    "Coleman County",
    "Collin County",
    "Colorado County",
    "Comal County",
    "Comanche County",
    "Cooke County",
    "Coryell County",
    "Culberson County",
    "Dallam County",
    "Dallas County",
    "Deaf Smith County",
    "Denton County",
    "Donley County",
    "Duval County",
    "Eastland County",
    "Ector County",
    "El Paso County",
    "Ellis County",
    "Erath County",
    "Falls County",
    "Fayette County",
    "Fisher County",
    "Fort Bend County",
    "Franklin County",
    "Freestone County",
    "Frio County",
    "Gaines County",
    "Galveston County",
    "Garza County",
    "Goliad County",
    "Gonzales County",
    "Gray County",
    "Grayson County",
    "Gregg County",
    "Grimes County",
    "Guadalupe County",
    "Hale County",
    "Hall County",
    "Hardeman County",
    "Hardin County",
    "Harris County",
    "Harrison County",
    "Hartley County",
    "Hays County",
    "Hemphill County",
    "Henderson County",
    "Hidalgo County",
    "Hill County",
    "Hockley County",
    "Hood County",
    "Hopkins County",
    "Houston County",
    "Howard County",
    "Hudspeth County",
    "Hunt County",
    "Hutchinson County",
    "Irion County",
    "Jackson County",
    "Jasper County",
    "Jeff Davis County",
    "Jefferson County",
    "Jim Wells County",
    "Johnson County",
    "Kaufman County",
    "Kenedy County",
    "Kinney County",
    "Kleberg County",
    "La Salle County",
    "Lamar County",
    "Lamb County",
    "Lampasas County",
    "Lavaca County",
    "Lee County",
    "Leon County",
    "Liberty County",
    "Limestone County",
    "Lipscomb County",
    "Live Oak County",
    "Llano County",
    "Lubbock County",
    "Madison County",
    "Marion County",
    "Martin County",
    "Matagorda County",
    "Maverick County",
    "McCulloch County",
    "McLennan County",
    "Medina County",
    "Midland County",
    "Milam County",
    "Mills County",
    "Mitchell County",
    "Montague County",
    "Montgomery County",
    "Moore County",
    "Morris County",
    "Nacogdoches County",
    "Navarro County",
    "Newton County",
    "Nolan County",
    "Nueces County",
    "Ochiltree County",
    "Orange County",
    "Palo Pinto County",
    "Panola County",
    "Parker County",
    "Parmer County",
    "Pecos County",
    "Polk County",
    "Potter County",
    "Presidio County",
    "Randall County",
    "Reeves County",
    "Refugio County",
    "Roberts County",
    "Robertson County",
    "Runnels County",
    "Rusk County",
    "Sabine County",
    "San Augustine County",
    "San Jacinto County",
    "San Patricio County",
    "San Saba County",
    "Scurry County",
    "Shelby County",
    "Smith County",
    "Swisher County",
    "Tarrant County",
    "Taylor County",
    "Terrell County",
    "Terry County",
    "Titus County",
    "Tom Green County",
    "Travis County",
    "Trinity County",
    "Upshur County",
    "Upton County",
    "Uvalde County",
    "Val Verde County",
    "Van Zandt County",
    "Victoria County",
    "Walker County",
    "Waller County",
    "Ward County",
    "Washington County",
    "Webb County",
    "Wharton County",
    "Wichita County",
    "Wilbarger County",
    "Willacy County",
    "Williamson County",
    "Winkler County",
    "Wise County",
    "Wood County",
    "undefined"
]"


**/

function histodent(prop) {
  if (typeof prop == "function") {
    return TTI.histogram(TTI.incidents.map((o) => prop(o)));
  }
  return TTI.histogram(TTI.incidents.map((o) => o.spec[prop]));
}

function popularity(histo) {
  return Object.keys(histo)
    .map((key) => [key, histo[key]])
    .sort(TTI.sorter((row) => row[1]));

  var sorted = Object.keys(histo).sort(
    TTI.sorter(function (key) {
      return histo[key];
    })
  );

  return sorted;
}

function tryJPG(RR) {
  return function (callback) {
    //cl("trying jpg", RR);
    var url = `${TTI.baseURL}/images/RR/${RR}.jpg`;
    TTI.import(url, function (err, data) {
      if (err) {
        return callback(err);
      }
      callback(null, url);
    });
  };
}

function tryPNG(RR) {
  return function (callback) {
    //cl("trying png", RR);
    var url = `${TTI.baseURL}/images/RR/${RR}.png`;
    TTI.import(url, function (err, data) {
      if (err) {
        return callback(err);
      }
      callback(null, url);
    });
  };
}

function trySVG(RR) {
  return function (callback) {
    //cl("trying svg", RR);
    var url = `${TTI.baseURL}/images/RR/${RR}.svg`;
    TTI.import(url, function (err, data) {
      if (err) {
        return callback(err);
      }
      callback(null, url);
    });
  };
}
function tryCache(RR) {
  return function (callback) {
    //cl("trying cache", RR);
    if (!TTI.cache.icons[RR]) {
      return callback(RR + " not in cache");
    }
    return callback(null, TTI.cache.icons[RR]);
  };
}
function tryDefault(RR) {
  return function (callback) {
    //cl("trying default", RR);
    callback(null, `${TTI.baseURL}/images/RR/default.png`);
  };
}

function getIconURL(RR, callback) {
  ///console.log('giU called!!!',RR)
  async.tryEach(
    [tryCache(RR), trySVG(RR), tryPNG(RR), tryJPG(RR), tryDefault(RR)],
    function (err, url) {
      if (err) {
        return callback(err);
      }
      ///console.log('now in giu finish up',RR);
      TTI.cache.icons[RR] = url;
      callback(null, url);
    }
  );
}

TTI.isNumberInRange = function (num, numArray) {
  if (typeof num !== "number") {
    return false;
  }
  var min = Math.min.apply(null, numArray);
  if (num < min) {
    return false;
  }
  var max = Math.max.apply(null, numArray);
  if (num > max) {
    return false;
  }
  return true;
};
